
import React, { Fragment, ReactElement, useEffect, useState } from "react";

import { Box, CircularProgress, Grid, IconButton, Typography } from "@mui/material";
import { IconChevronDown, IconChevronUp } from "@tabler/icons-react";

import CustomImage from "src/components/ui/CustomImage/CustomImage";
import PageHeader from "src/components/ui/PageHeader/PageHeader";
import AppLayout from "src/layouts/AppLayout/AppLayout";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { selectApplications } from "src/store/reducers/applications/applicationsSlice";
import { selectBearerToken } from "src/store/reducers/auth/authSlice";
import { selectUserName, selectUserOperatorDisplayName, selectUserOperatorId } from "src/store/reducers/userInfo/userInfoSlice";
import { getApplications } from "src/store/thunks/applications/getAll/getApplications";
import { getOperatorUserInfo } from "src/store/thunks/operator/get/getOperatorUserInfo";
import { getUserInfo } from "src/store/thunks/user/getInfo/getUserInfo";
import { getUserTypes } from "src/store/thunks/user/getTypes/getTypes";

import AppCard from "./AppCard/AppCard";

import useStyles from "./Dashboard.styles";

const Dashboard: React.FC = (): ReactElement => {
  const dispatch = useAppDispatch();
  const { classes } = useStyles();
  const userName = useAppSelector(selectUserName);
  const applications = useAppSelector(selectApplications);
  const bearerToken = useAppSelector(selectBearerToken);
  const userOperatorId = useAppSelector(selectUserOperatorId);
  const userOperatorDisplayName = useAppSelector(selectUserOperatorDisplayName);

  const [displaySubscribedApps, setDisplaySubscribedApps] = useState(true);
  const [displayUnsubscribedApps, setDisplayUnsubscribedApps] = useState(false);

  const handleToggleSubscribed = () => { setDisplaySubscribedApps(!displaySubscribedApps); };
  const handleToggleUnsubscribed = () => { setDisplayUnsubscribedApps(!displayUnsubscribedApps); };

  useEffect(() => {
    dispatch(getApplications());
    dispatch(getUserInfo()).then(resp => {
      if (!resp.type.includes("rejected")) {
        dispatch(getUserTypes());
        dispatch(getOperatorUserInfo(resp.payload.operator_id));
      }
    });
  }, []);

  return (
    <Fragment>
      {userName
        ? (
          <Grid
            item
            container
            justifyContent={"center"}
            alignItems={"center"}
            className={classes.root}
          >
            <AppLayout>
              <PageHeader
                title={`${userOperatorDisplayName} Portal`}
                subtitle={`Welcome back, ${userName}! Here’s your daily snapshot.`}
              />
              <Box mt={2} mb={1}>
                <Grid container alignItems={"center"}>
                  <Typography variant={"body1"} >
                    Available apps in your subscription
                  </Typography>
                  <IconButton
                    color={"primary"}
                    aria-label={"toggle subscribed apps"}
                    data-testid={"toggle-subscribed-button"}
                    onClick={() => handleToggleSubscribed()}
                  >
                    {displaySubscribedApps
                      ? <IconChevronDown data-testid={"subscribed-less-icon"} />
                      : <IconChevronUp data-testid={"subscribed-more-icon"} />
                    }
                  </IconButton>
                </Grid>
              </Box>
              {displaySubscribedApps
                ? <Box my={2} data-testid={"subscribed-apps"}>
                  <Grid container>
                    {applications?.map((application) => {
                      const availableOperators = application?.operators?.map(
                        operator => operator.id
                      );
                      if (!availableOperators?.includes(userOperatorId)) {
                        return null;
                      } // todo: add more apps when compatible with bearer token
                      return <AppCard
                        key={application.id}
                        name={application.name}
                        icon={<CustomImage
                          imageAlt={application.name}
                          imageName={application.ident}
                          className={classes.iconAppCard}
                        />}
                        tablet
                        desktop link={
                        `${application.uri}` +
                        `${application.ident === "property-management" || application.ident === "merchant-onboarding"
                          ? `/authenticate?bearer=${bearerToken}`
                          : ""
                        }`
                      }
                      />;
                    })}
                  </Grid>
                </Box>
                : null
              }
              <Box mt={5} mb={1}>
                <Grid container alignItems={"center"}>
                  <Typography variant={"body1"}>
                    Other Applications
                  </Typography>
                  <IconButton
                    color={"primary"}
                    aria-label={"toggle unsubscribed apps"}
                    data-testid={"toggle-unsubscribed-button"}
                    onClick={() => handleToggleUnsubscribed()}
                  >
                    {displayUnsubscribedApps
                      ? <IconChevronDown data-testid={"unsubscribed-less-icon"} />
                      : <IconChevronUp data-testid={"unsubscribed-more-icon"} />
                    }
                  </IconButton>
                </Grid>
              </Box>
              {displayUnsubscribedApps
                ? <Box my={2} data-testid={"unsubscribed-apps"}>
                  <Grid container>
                    {applications?.map((application) => {
                      const availableOperators = application?.operators?.map(
                        operator => operator.id
                      );
                      if (availableOperators?.includes(userOperatorId)) {
                        return null;
                      }
                      return <AppCard
                        key={application.id}
                        name={application.name}
                        icon={<CustomImage
                          imageAlt={application.name}
                          imageName={application.ident}
                          className={classes.iconAppCard}
                        />}
                        tablet
                        desktop link={`${application.uri}`}
                      />;
                    })}
                  </Grid>
                </Box>
                : null
              }
            </AppLayout>
          </Grid>
          )
        : (
          <Grid
            className={classes.loadingSpinnerContainer}
            container
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Grid item mt={2}>
              <CircularProgress size={"4rem"} className={classes.loadingSpinner} />
              <Typography variant={"h5"}>Loading...</Typography>
            </Grid>
          </Grid>
          )}
    </Fragment>
  );
};

export default Dashboard;
